import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../assets/lottie/tack.json'

import SEO from '../components/SEO'
import CalendarCTA from '../components/CalendarCTA'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const IndexPage = () => (
  <div className="TackPage">
    <SEO />

    <h1>Tack, see you soon!</h1>

    <div className="ThankYouAnimation">
      <Lottie options={defaultOptions} />
    </div>

    <CalendarCTA />

  </div>
)

export default IndexPage
