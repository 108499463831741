import React, { useRef } from 'react'

import {
  motion,
  useTransform,
  useMotionValue,
  useSpring
} from 'framer-motion'

import './CalendarCTA.css'

const CalendarCTA = () => {
  const buttonRef = useRef(null)

  // hover state
  const x = useMotionValue(0)
  const sx = useSpring(x, {
    // stiffness: 400,
    // damping: 90,
    stiffness: 1000,
    damping: 2000,
    velocity: 0
  })
  // const y = useMotionValue(0);
  const polygon = useTransform(
    sx,
    [0, 1.5],
    [
      'polygon(0% 5%, 100% 0%, 95% 100%, 0 100%)',
      'polygon(10% 0%, 100% 0%, 100% 90%, 0 100%)'
      // `polygon(0% 0%, 100% 0%, 90% 100%, 0 90%)`,
    ]
  )

  // const snap = useAnimation()

  // const [hover, setHover] = useState()

  // const left = currentTab.current.offsetLeft

  return (
    <motion.a
      className={'CalendarCTA'}
      href="/oktoberfest.ICS"
      initial={{ scale: 1.01 }}
      whileHover={{ scale: 1.2 }}
      onMouseMove={(e, i) => {
        const leftX =
              buttonRef.current.offsetParent.offsetLeft +
              buttonRef.current.offsetLeft
        const topY =
              buttonRef.current.offsetParent.offsetTop +
              buttonRef.current.offsetTop
        const width = buttonRef.current.clientWidth
        const height = buttonRef.current.clientHeight

        const dX = (e.clientX - leftX) / width
        const dY = (e.clientY - topY) / height
        x.set(dX * dX + dY * dY)
      }}
    >

      <div
        ref={buttonRef} className="CalendarCTAcontent">
        <motion.div
          className={'CalendarCTAbg'}
          style={{ clipPath: polygon }}
        ></motion.div>
        <span className="CalendarCTAtext">Add to Calendar</span>
      </div>
    </motion.a>
  )
}

export default CalendarCTA
